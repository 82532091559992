import React from "react";
import Body from "../landing-page/Body";

export default function LandingPage() {
  return (
    <div>
      <Body />
    </div>
  );
}
